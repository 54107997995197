import { useEffect, useRef, useState } from 'react';
import { Message, Roles } from 'immi-types';
import cn from 'classnames';
import styles from '@/styles/Input.module.scss';
import { RiSendPlane2Line as SendIcon } from 'react-icons/ri';
import { withTimestamp } from '@/lib/utils';
import { useApp } from '@/context/app';
import { useTranslation } from "react-i18next";

interface InputProps {
    onSendMessage: (message: Message) => void,
}

function Input({ onSendMessage }: InputProps) {
  const { t } = useTranslation();
  const { isLoading } = useApp();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [text, setText] = useState('');
  const [sent, setSent] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!text.trim()) return;

    onSendMessage(withTimestamp({ content: text, role: Roles.User }));
    setText('');
    setSent(true);
  };

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        setSent(false);
      }, 1200);
    }
  }, [sent])
  

  return (
    <form className={styles.form} onSubmit={handleFormSubmit}>
        <textarea
          ref={textareaRef}
          className={styles.input}
          placeholder={t('inputPlaceholder') || 'Start typing...'}
          value={text}
          onChange={handleInputChange}
          rows={1}
          maxLength={250}
        />
        <button
          className={cn(styles.sendBtn, sent && styles.sent)}
          disabled={isLoading}
          type="submit"
        >
          <SendIcon />
        </button>
    </form>
  );
}

export default Input;
