import { FC } from "react";
import { Message } from "immi-types";
import cl from "classnames";
import styles from "@/styles/Message.module.scss";
import { epochToTimeString } from "@/lib/utils";

interface MessageListProps {
  message: Message;
}

const MessageBubble: FC<MessageListProps> = ({ message }) => {
  return (
    <div className={cl(styles.bubble, styles[message.role], message.isError && styles.isError)}>
      {message.content}
      {
        message.timestamp &&
        <span className={styles.time}>
          { epochToTimeString(message.timestamp) }
        </span>
      }
    </div>
  );
};

export default MessageBubble;
