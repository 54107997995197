const enTranslation = {
    appName: 'Immi',
    inputPlaceholder: 'Start typing...',
    language: 'Language',
    english: 'English',
    ukrainian: 'Ukrainian',
    russian: 'Russian',
    clearConversation: 'Clear conversation',
    defaultError: 'Uh-oh! It seems like we encountered an error. We\'re actively working to fix it. Please try again later.',
    welcomeMessage: 'Hi 👋, I\'m Immi, your AI assistant.',
  };
  
  export default enTranslation;