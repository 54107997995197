import styles from "@/styles/Options.module.scss";
import { MouseEventHandler } from "react";

interface OptionButtonProps {
    children?: React.ReactNode,
    onClick: MouseEventHandler<HTMLButtonElement>,
    text: React.ReactNode | string,
    icon?: React.ReactNode,
}

const OptionButton = ({ children, onClick, text, icon }: OptionButtonProps) => {

  return (
    <>
      <button className={styles.optionButton} onClick={onClick}><span>{text}</span> {icon}</button>
      { children }
    </>
  );
};

export default OptionButton;
