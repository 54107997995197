import { useState } from 'react';
import { MdChevronRight as ChevronIcon } from 'react-icons/md'
import LanguageSelectorPopup from './languageSelectorPopup';
import OptionButton from './optionButton';
import TranslatedString from './translatedString';

const LanguageSelector = () => {
  const [show, setShow] = useState(false);

  return (
      <OptionButton
        onClick={() => setShow(!show)}
        text={<TranslatedString translationKey='language' />}
        icon={<ChevronIcon />}
      >
        {
          show && <LanguageSelectorPopup />
        }
      </OptionButton>
  );
};

export default LanguageSelector;
