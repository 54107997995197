import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/styles/globals.css';
import App from '@/App';
import reportWebVitals from '@/reportWebVitals';
import AppProvider from '@/context/app';
import './i18n';

const rootEl = document.getElementById('chatbot-root') as HTMLElement;
const root = ReactDOM.createRoot(rootEl);

root.render(
  <AppProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
