import { useState } from 'react';
import cn from 'classnames';
import styles from '@/styles/Header.module.scss';
import { GrClose as CloseIcon } from 'react-icons/gr';
import { BsThreeDots as OptionsIcon } from 'react-icons/bs';
import { useApp } from '@/context/app';
import Options from './options';
import TranslatedString from './translatedString';

const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

function Header() {
  const { setOpen } = useApp();
  const [showOptions, setShowOptions] = useState(false);
  const avatarUrl = PUBLIC_URL + '/avatar.svg';

  return (
    <>
      <div className={styles.avatar}>
        <img
          src={avatarUrl}
          alt="Chatbot avatar"
          width={32}
          height={32}
        />
        <span>
          <TranslatedString translationKey='appName' />
        </span>
      </div>

      <div className={styles.icons}>
        <button className={styles.iconButton} onClick={() => setShowOptions(!showOptions)}>
          <OptionsIcon className={cn(styles.icon, showOptions && styles.active)} />
        </button>
        {
          showOptions && <Options />
        }
        <button className={styles.iconButton} onClick={() => setOpen(false)}>
          <CloseIcon className={styles.icon} />
        </button>
      </div>
    </>
  );
}

export default Header;
