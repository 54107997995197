import Chat from '@/components/chat'
import styles from '@/styles/App.module.scss';
import cn from 'classnames';
import { FiMessageSquare } from 'react-icons/fi';
import { useApp } from '@/context/app';

const App = () => {
  const { isOpen, setOpen } = useApp();

  return (
    <div className={cn(styles.appContainer)}>
      {
        isOpen && <Chat/>
      }
      <button className={styles.activationButton} onClick={() => setOpen(!isOpen)}>
        <FiMessageSquare />
      </button>
    </div>
  )
}

export default App;