import styles from "@/styles/Options.module.scss";
import ClearConversation from "./clearConversation";
import LanguageSelector from "./languageSelector";
import { useApp } from "@/context/app";

const Options = () => {
  const { messageHistory } = useApp();
  const appOptions = [
    <LanguageSelector />,
    messageHistory?.length ? <ClearConversation /> : null,
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {appOptions.filter((el) => el).map((option, idx) => (
          <li className={styles.listItem} key={idx}>
            {option}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Options;
