import { FC, Fragment, useEffect, useRef } from "react";
import { Message } from "immi-types";
import MessageBubble from "@/components//messageBubble";
import styles from "@/styles/MessageList.module.scss";
import { useApp } from "@/context/app";
import LoadingBubble from "./loadingBubble";

interface MessageListProps {
  messages: Message[];
}

const MessageList: FC<MessageListProps> = ({ messages }) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const { isLoading } = useApp();
  let lastDisplayedDate: any = null;
  
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }
  
  useEffect(() => {
    scrollToBottom()
  }, [messages, isLoading]);

  return (
    <div className={styles.messageList}>
      {
        messages.map((message) => {
          const currentDate = (message.timestamp ? new Date(message.timestamp) : new Date()).toLocaleDateString("en-GB");
          const showDate = currentDate !== lastDisplayedDate;

          lastDisplayedDate = currentDate;

          return <Fragment key={message.id}>
            {showDate && (
              <div
                className={styles.date}
              >
                { currentDate }
              </div>
            )}
            <MessageBubble 
              message={message}
            />
          </Fragment>
        })
      }
      {
        isLoading && <LoadingBubble />
      }
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
