import cn from "classnames";
import { FiCheckCircle as SelectedIcon } from "react-icons/fi";
import styles from "@/styles/Options.module.scss";
import { LANGUAGES } from "@/constants";
import OptionButton from "./optionButton";
import { useTranslation } from 'react-i18next';
import TranslatedString from "./translatedString";

const LanguageSelectorPopup = () => {
  const { i18n } = useTranslation();
  const handler = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <div className={cn(styles.languagePopup, styles.container)}>
      <ul className={styles.list}>
        {LANGUAGES.map(({ name, code }) => (
          <li className={cn(styles.listItem, styles.languageItem)} key={code}>
            <OptionButton
              onClick={() => handler(code)}
              text={<TranslatedString translationKey={name} />}
              icon={i18n.language === code ? <SelectedIcon /> : <></>}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelectorPopup;
