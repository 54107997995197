import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '@/locales/en';
import uk from '@/locales/uk';
import ru from '@/locales/ru';
import { getDefaultLanguage } from '@/lib/utils';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    uk: { translation: uk },
    ru: { translation: ru },
  },
  lng: getDefaultLanguage(),
  fallbackLng: 'en',
  interpolation: {

  },
});

export default i18n;