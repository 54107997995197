import { useEffect, useRef } from 'react';
import { Conversation, Message } from 'immi-types';

const API_URL = process.env.REACT_APP_API_URL;

export async function fetchAnswer(messages: Message[] | null): Promise<any> {
    if (!messages) return Promise.resolve(null);

    return fetch(`${API_URL}/answer`, {
      method: 'POST',
      body: JSON.stringify(messages)
    }).then(res => res.json());
}

export async function saveMessage(message: Message): Promise<Message> {
    return fetch(`${API_URL}/message`, {
      method: 'POST',
      body: JSON.stringify(message)
    }).then(res => res.json());
}

export async function createConversation(data?: any): Promise<Conversation> {
    return fetch(`${API_URL}/conversation`, {
      method: 'POST',
      body: JSON.stringify(data)
    }).then(res => res.json());
}

export async function getConversation(id: string): Promise<Conversation> {
    return fetch(`${API_URL}/conversation/${id}`, {
      method: 'GET',
    }).then(res => res.json());
}

export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  
  return ref.current;
}