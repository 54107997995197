const ruTranslation = {
  appName: 'Имми',
  inputPlaceholder: 'Начните печатать...',
  language: 'Язык',
  english: 'Английский',
  ukrainian: 'Украинский',
  russian: 'Русский',
  clearConversation: 'Очистить разговор',
  defaultError: 'Упс! Похоже, мы столкнулись с ошибкой. Мы активно работаем над ее исправлением. Пожалуйста, повторите попытку позже.',
  welcomeMessage: 'Привет! Я Имми, Ваш ИИ помощник😊 ',
};

export default ruTranslation;