import { BiReset as ClearIcon } from 'react-icons/bi'
import { CONVERSATION_ID_LS_KEY } from "@/constants";
import { useApp } from "@/context/app";
import OptionButton from './optionButton';
import TranslatedString from './translatedString';

const ClearConversation = () => {
  const { setConversationId } = useApp();
  const handler = () => {
    window.localStorage.removeItem(CONVERSATION_ID_LS_KEY);
    setConversationId('');
  };

  return <OptionButton onClick={handler} text={<TranslatedString translationKey='clearConversation' />} icon={<ClearIcon />} />
};

export default ClearConversation;
