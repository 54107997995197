export const withTimestamp = (obj: any) => {
  return {
    timestamp: Date.now(),
    ...obj,
  };
};

export const epochToTimeString = (epoch: number) => {
  const date = new Date(epoch);

  return date
    .toLocaleTimeString("en-CA", {
      timeStyle: "short",
      hourCycle: "h12",
    })
    .replaceAll(".", "");
};

export const getDefaultLanguage = () => {
  const browserLanguage = navigator.language.split("-")[0];
  
  return browserLanguage || "en";
};
