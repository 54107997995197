import { Message } from "immi-types";

export const EMPTY_RESPONSE_ERROR_CODE = 'EMPTY_RESPONSE';

export const CONVERSATION_ID_LS_KEY = 'conversationId';

export const LANGUAGES = [
    {
        name: 'english',
        code: 'en',
    },
    {
        name: 'ukrainian',
        code: 'uk',
    },
    {
        name: 'russian',
        code: 'ru',
    }
]

export const DEFAULT_MESSAGES: Message[] = [];