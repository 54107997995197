import { Conversation, Message } from "immi-types";
import { CONVERSATION_ID_LS_KEY } from "@/constants";
import { createConversation, getConversation } from "@/lib/hooks";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

interface AppContextProps {
  isOpen: boolean;
  conversationId: string;
  messageHistory: Array<Message>;
  isLoading: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setConversationId: Dispatch<SetStateAction<string>>;
  setMessageHistory: Dispatch<SetStateAction<Array<Message>>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  startConversation: boolean;
}

const AppContext = createContext<AppContextProps>({
  isOpen: false,
  conversationId: "",
  messageHistory: [],
  isLoading: false,
  setOpen: () => false,
  setConversationId: () => "",
  setMessageHistory: () => [],
  setLoading: () => false,
  startConversation: false,
});

const AppProvider = ({ children }: { children: ReactNode}) => {
  const [isOpen, setOpen] = useState(true);
  const [conversationId, setConversationId] = useState(
    window.localStorage.getItem(CONVERSATION_ID_LS_KEY) || ""
  );
  const [messageHistory, setMessageHistory] = useState<Array<Message>>([]);
  const [isLoading, setLoading] = useState(false);
  const [startConversation, setStartConversation] = useState(false);

  
  useEffect(() => {
    const newConversation = () => {
      setMessageHistory([]);
      setStartConversation(false);
      
      createConversation().then((conversation: Conversation) => {
        const id = conversation.id;
        setConversationId(id);
        window.localStorage.setItem(CONVERSATION_ID_LS_KEY, id);
  
        setTimeout(() => {
          setStartConversation(true);
        }, 500);
      }
    )};

    try {
      if (!conversationId) {
        newConversation();
      } else {
        setStartConversation(false);
        getConversation(conversationId).then((conversation: Conversation) => {
          if (!conversation?.id) return newConversation();

          if (conversation?.messages?.length) {
            setMessageHistory(conversation.messages);
          }
        });
      }
    } catch (error) {
      console.log(error);
      newConversation();
    }
  }, [conversationId]);

  return (
    <AppContext.Provider
      value={{
        isOpen,
        isLoading,
        setOpen,
        setLoading,
        conversationId,
        setConversationId,
        messageHistory,
        setMessageHistory,
        startConversation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);

export default AppProvider;
