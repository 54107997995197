import { useCallback, useEffect, useState } from "react";
import { Message, Roles } from "immi-types";
import styles from "@/styles/Chat.module.scss";
import Header from "@/components/header";
import MessageList from "@/components/messagesList";
import Input from "@/components/input";
import { saveMessage, fetchAnswer, usePrevious } from "@/lib/hooks";
import { withTimestamp } from "@/lib/utils";
import { useApp } from "@/context/app";
import { EMPTY_RESPONSE_ERROR_CODE, DEFAULT_MESSAGES } from "@/constants";
import { useTranslation } from 'react-i18next';

export default function Chat() {
  const { t } = useTranslation();
  const { setLoading, conversationId, messageHistory, startConversation } = useApp();
  const prevStartConversation = usePrevious(startConversation);
  const [message, setMessage] = useState<Message | null>(null);
  const [messagesList, setMessagesList] = useState<Message[]>(DEFAULT_MESSAGES);
  const [error, setError] = useState<any>(null);

  const addMessage = useCallback(async (message: Message) => {
    setMessagesList((prevMessages) => [...prevMessages, message]);
    
    saveMessage({ ...message, conversationId });
  }, [conversationId]);

  useEffect(() => {
    if (startConversation && startConversation !== prevStartConversation && conversationId) {
      addMessage(
        withTimestamp({
          role: Roles.Assistant,
          content: t('welcomeMessage'),
        })
      );
    }
  }, [startConversation, prevStartConversation, conversationId, addMessage, t]);

  useEffect(() => {
    setMessagesList(messageHistory);
  }, [messageHistory]);

  useEffect(() => {
    if (error) {
      console.info('Seting error message. Error: ', error);
      addMessage(
        withTimestamp({
          role: Roles.Assistant,
          isError: true,
          content: t('defaultError'),
        })
      );
    }
  }, [error, addMessage, t]);

  useEffect(() => {
    if (message) {
      addMessage(message);
    }
  }, [message, addMessage]);

  const getAnswer = useCallback(async () => {
    setLoading(true);
    try {
      console.log({ messagesList });
      const answerResponse = await fetchAnswer(messagesList);
      console.log(answerResponse);

      if (!answerResponse.content) throw new Error(EMPTY_RESPONSE_ERROR_CODE);
      

      addMessage(
        withTimestamp({
          role: Roles.Assistant,
          content: answerResponse.content,
        })
      );
    } catch (error) {
      console.error('getAnswer error', error);

      setError(error || 'Error getting answer');
    } finally {
      setLoading(false);
    }
  }, [messagesList, setLoading, addMessage]);

  useEffect(() => {
    if (
      messagesList.length &&
      messagesList[messagesList.length - 1].role === Roles.User
    ) {
      getAnswer();
    }
  }, [messagesList, getAnswer]);

  return (
    <section className={styles.chat}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.messages}>
        <MessageList messages={messagesList}/>
      </div>
      <div className={styles.input}>
        <Input onSendMessage={setMessage} />
      </div>
    </section>
  );
}
