const ukTranslation = {
    appName: 'Іммі',
    inputPlaceholder: 'Почніть вводити...',
    language: 'Мова',
    english: 'Англійська',
    ukrainian: 'Українська',
    russian: 'Російська',
    clearConversation: 'Очистити розмову',
    defaultError: 'Ой! Здається, ми стикнулись з помилкою. Ми активно працюємо над виправленням. Будь ласка, спробуйте знову пізніше.',
    welcomeMessage: 'Привіт! Я Іммі, Ваш ШІ помічник😊',
  };
  
  export default ukTranslation;