import { FC } from "react";
import { Roles } from "immi-types";
import cl from "classnames";
import styles from "@/styles/Message.module.scss";
import loadingStyles from "@/styles/LoadingBubble.module.scss";

interface MessageListProps {
}

const LoadingBubble: FC<MessageListProps> = () => {
  return (
    <div className={cl(styles.bubble, styles[Roles.Assistant])}>
      <div className={loadingStyles.typing}></div>
    </div>
  );
};

export default LoadingBubble;
