import { useTranslation } from "react-i18next";

interface TranslatedStringProps {
  translationKey: string;
  data?: any;
}

const TranslatedString: React.FC<TranslatedStringProps> = ({
  translationKey,
  data = {},
}) => {
  const { t } = useTranslation();

  return <>{t(translationKey, data)}</>;
};

export default TranslatedString;
